@import '../variables';

@mixin query-max($display) {
    @each $key, $value in $display-breakpoints {
        @if $display == $key {
            @media (max-width: $value + 'px') {
                @content;
            }
        }
    }
}

@mixin query-min($display) {
    @each $key, $value in $display-breakpoints {
        @if $display == $key {
            @media (min-width: $value + 'px') {
                @content;
            }
        }
    }
}