@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

:root {
    --primary: #119ad7;
    /* --primary:#1166d7; */
    --light-primary: rgba(17, 154, 215, 0.2);
    --light-primary-hex: #cfebf7;
    --dark-primary: #081752;
    --red: #ff78a3;
    --green: #78df4e;
    --yellow: #e7b852;
    --light-red: rgba(255, 120, 163, 0.2);
    --light-grey: #b0b0b0;
    --grey: #888;
    --text: #555;

    --max-width: 1300px;
    --max-form-width: 900px;
    font-size: 14px;
}

* {
    box-sizing: border-box;
    font-family: 'Lato', arial, sans-serif;
}

body {
    margin: 0;
    color: var(--text);
    top: 0px !important; 
}

/*Visor*/
.text-area-pad {
    padding-top: 98px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    font-family: 'Roboto' !important;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 2.5rem;
    color: #000000;
    border: 0px solid #E6E5E5;
    border-bottom: 1px solid #E6E5E5 !important;
    background: transparent;
    resize: none;
    margin-bottom: 14px !important;
}
.mat-tab-label {
    padding: 0 0px !important;
}
/*.mat-tab-body {
    border-radius: 0px 0px 18px 18px !important;
}
.cdk-virtual-scroll-orientation-vertical .cdk-virtual-scroll-content-wrapper {
    border-radius: 0px 0px 18px 18px !important;
}*/

/*Onboarding*/
.copilot-custom{
    color: #ffffff;
    font-weight: 600;
    background: #1E9AD6;
    font-size: .85rem;
    padding: 1rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.10);
    border-radius: 8px;
    width: 325px;
}

.copilot-custom::before {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    top: 45%;
    right: -13px;
    transform: rotate(180deg);
    border-top: 7px solid transparent;
    border-right: 13px solid #1E9AD6;
    border-bottom: 7px solid transparent;
    border-left: 10px solid transparent;
}
  
.copilot-custom .footer-btn{
    padding-top: 0.75rem;
    display: flex;
    justify-content: space-between;
}
.copilot-custom .footer-btn button{
    cursor: pointer;
    background: #FFFFFF;
    border: 0;
    font-family: 'Poppins';
    color: #119ad7;
    font-size: 12px;
    font-weight: 700;
    border-radius: 2px;
    padding: 0.35rem 1.4rem;
}

.copilot-custom .footer-btn button:hover{
    -webkit-transition: all 100ms ease-out;
    -moz-transition: all 100ms ease-out;
    -o-transition: all 100ms ease-out;
    transition: all 100ms ease-out;
    transform: scale(1.01);
}

.ngx-copilot-init .ngx-copilot-pulse {
    background-color: #119ad7 !important;
}

.ngx-wrapper-overview .copilot-view {
    background-color:rgba(255, 255, 255, 0) !important;
    max-width: 345px !important;
}

.ngx-copilot-active .copilot-view {
    width: 100vw;
    left: 63% !important;
    margin: 0 !important;
}

.ngx-copilot-active-step-1 .copilot-view{
    width: 120vw;
    top: 22.5vh !important;
    left: 62.5% !important;
    margin: 0 !important;
}

.ngx-copilot-active-step-2 .copilot-view{
    width: 120vw;
    top: 28vh !important;
    left: 63.5% !important;
    margin: 0 !important;
}

.ngx-copilot-active-step-3 .copilot-view{
    width: 120vw;
    top: 66.5vh !important;
    left: 20% !important;
    margin: 0 !important;
}

.ngx-copilot-active-step-4 .copilot-view{
    width: 120vw;
    top: 71.5vh !important;
    left: 19.5% !important;
    margin: 0 !important;
}

.ngx-copilot-active-step-5 .copilot-view{
    width: 120vw;
    top: 75.5vh !important;
    left: 24% !important;
    margin: 0 !important;
}

.ngx-copilot-init .ngx-copilot-pulse {
    background-color:rgba(255, 255, 255, 0) !important;
}

.ngx-wrapper-overview {
    height: 100% !important;
    overflow: hidden;
}

.copilot-custom h3{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
    margin: 0;
}
.copilot-custom .num-step{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
    justify-content: start !important;
    padding-top: 0.35rem;
}

.btn-omitir {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500 !important;
    font-size: 12px !important;
    color: #FFFFFF !important;
    text-decoration: underline;
    background-color: transparent !important;
}

.btn-omitir:hover {
    text-decoration: none;
}

:root{
	--zoneColor:rgba(255, 255, 255, 0) !important;
    --zone: -webkit-radial-gradient(var(--zoneY) var(--zoneX), circle, transparent var(--zoneSize), var(--zoneColor) var(--zoneOver)) !important;
}

.mat-tab-label-container {
    max-width: 74.5vw; //75.9vw;
}

@media all and (max-width: 600px) {
    .ngx-copilot-active .copilot-view{
        width: 120vw;
        top: 40vh !important;
        left: 20% !important;
        margin: 0 !important;
    }
    .ngx-copilot-active .copilot-view pre{
        overflow-x: auto;
    }
}

@media all and (max-width: 1600px) {
    .mat-tab-label-container {
        max-width: 62vw; //71.9vw;
    }
    .ngx-copilot-active-step-1 .copilot-view {
        width: 120vw;
        top: 23.5vh !important;
        left: 56% !important;
        margin: 0 !important;
    }

    .ngx-copilot-active-step-2 .copilot-view {
        width: 120vw;
        top: 28vh !important;
        left: 57.5% !important;
        margin: 0 !important;
    }
    
    .ngx-copilot-active-step-3 .copilot-view {
        width: 120vw;
        top: 69vh !important;
        left: 14% !important;
        margin: 0 !important;
    }

    .ngx-copilot-active-step-4 .copilot-view {
        width: 120vw;
        top: 72vh !important;
        left: 14.5% !important;
        margin: 0 !important;
    }
    
    .ngx-copilot-active-step-5 .copilot-view {
        width: 120vw;
        top: 75vh !important;
        left: 19.5% !important;
        margin: 0 !important;
    }
}

@media all and (max-width: 1440px) {
    .ngx-copilot-active-step-1 .copilot-view {
        width: 120vw;
        top: 22.5vh !important;
        left: 52.5% !important;
        margin: 0 !important;
    }

    .ngx-copilot-active-step-2 .copilot-view {
        width: 120vw;
        top: 28vh !important;
        left: 52.5% !important;
        margin: 0 !important;
    }
    
    .ngx-copilot-active-step-3 .copilot-view {
        width: 120vw;
        top: 62vh !important;
        left: 10.5% !important;
        margin: 0 !important;
    }

    .ngx-copilot-active-step-4 .copilot-view {
        width: 120vw;
        top: 72vh !important;
        left: 11.5% !important;
        margin: 0 !important;
    }
    
    .ngx-copilot-active-step-5 .copilot-view {
        width: 120vw;
        top: 75vh !important;
        left: 16.5% !important;
        margin: 0 !important;
    }
    .mat-tab-label-container {
        max-width: 50vw; //63.4vw;
    }
}

@media all and (max-width: 1200px) {
    .ngx-copilot-active-step-1 .copilot-view {
        width: 120vw;
        top: 22.5vh !important;
        left: 42.5% !important;
        margin: 0 !important;
    }

    .ngx-copilot-active-step-2 .copilot-view {
        width: 120vw;
        top: 28vh !important;
        left: 44% !important;
        margin: 0 !important;
    }
    
    .ngx-copilot-active-step-3 .copilot-view {
        width: 120vw;
        top: 51vh !important;
        left: 3% !important;
        margin: 0 !important;
    }

    .ngx-copilot-active-step-4 .copilot-view {
        width: 120vw;
        top: 72vh !important;
        left: 4.5% !important;
        margin: 0 !important;
    }
    
    .ngx-copilot-active-step-5 .copilot-view {
        width: 120vw;
        top: 75vh !important;
        left: 10.5% !important;
        margin: 0 !important;
    }
    .mat-tab-label-container {
        max-width: 40vw; //63.4vw;
    }
}

@media all and (max-width: 1024px) {
    .ngx-copilot-active-step-1 .copilot-view {
        width: 120vw;
        top: 22.5vh !important;
        left: 33.5% !important;
        margin: 0 !important;
    }
    .ngx-copilot-active-step-2 .copilot-view {
        width: 120vw;
        top: 28vh !important;
        left: 35% !important;
        margin: 0 !important;
    }
    .ngx-copilot-active-step-3 .copilot-view {
        width: 120vw;
        top: 58vh !important;
        left: 1.5% !important;
        margin: 0 !important;
    }
    .ngx-copilot-active-step-4 .copilot-view {
        width: 120vw;
        top: 73vh !important;
        left: 1.5% !important;
        margin: 0 !important;
    }
    .ngx-copilot-active-step-5 .copilot-view {
        width: 120vw;
        top: 75vh !important;
        left: 3% !important;
        margin: 0 !important;
    }
    .mat-tab-label-container {
        max-width: 39vw; //57.2vw;
    }
}

@media all and (max-width: 900px) {
    .ngx-copilot-active-step-1 .copilot-view {
        width: 120vw;
        top: 22.5vh !important;
        left: 24.5% !important;
        margin: 0 !important;
    }
    .ngx-copilot-active-step-2 .copilot-view {
        width: 120vw;
        top: 28vh !important;
        left: 26% !important;
        margin: 0 !important;
    }
    .ngx-copilot-active-step-3 .copilot-view {
        width: 120vw;
        top: 51.5vh !important;
        left: 1.5% !important;
        margin: 0 !important;
    }
    .ngx-copilot-active-step-4 .copilot-view {
        width: 120vw;
        top: 73vh !important;
        left: 1.5% !important;
        margin: 0 !important;
    }
    .ngx-copilot-active-step-5 .copilot-view {
        width: 120vw;
        top: 75vh !important;
        left: 1.5% !important;
        margin: 0 !important;
    }
    .mat-tab-label-container {
        max-width: 29vw; //51.2vw;
    }
}

@media all and (max-width: 767px) {
    .tab-individual {
        position: relative;
    }

    .copilot-custom {
        width: 330px;
    }
    .copilot-custom::before {
        top: -2%;
        left: 50%;
        transform: rotate(90deg);
    }
    .ngx-copilot-active-step-1 .copilot-view{
        width: 120vw;
        top: 58vh !important;
        left: 26% !important;
        margin: 0 !important;
    }

    .ngx-copilot-active-step-2 .copilot-view{
        width: 120vw;
        top: 64vh !important;
        left: 26% !important;
        margin: 0 !important;
    }

    .ngx-copilot-active-step-3 .copilot-view{
        width: 120vw;
        top: 43vh !important;
        left: 26% !important;
        margin: 0 !important;
    }

    .ngx-copilot-active-step-4 .copilot-view{
        width: 120vw;
        top: 70vh !important;
        left: 26% !important;
        margin: 0 !important;
    }

    .ngx-copilot-active-step-5 .copilot-view{
        width: 120vw;
        top: 71vh !important;
        left: 26% !important;
        margin: 0 !important;
    }
    .mat-tab-label-container {
        max-width: 658px;
    }
}

@media all and (max-width: 600px) {
    .copilot-custom {
        width: 250px;
    }

    .ngx-copilot-active-step-1 .copilot-view{
        width: 120vw;
        top: 52vh !important;
        left: 10% !important;
        margin: 0 !important;
    }

    .ngx-copilot-active-step-2 .copilot-view{
        width: 120vw;
        top: 58.5vh !important;
        left: 10% !important;
        margin: 0 !important;
    }

    .ngx-copilot-active-step-3 .copilot-view{
        width: 120vw;
        top: 42vh !important;
        left: 10% !important;
        margin: 0 !important;
    }

    .ngx-copilot-active-step-4 .copilot-view{
        width: 120vw;
        top: 68vh !important;
        left: 10% !important;
        margin: 0 !important;
    }

    .ngx-copilot-active-step-5 .copilot-view{
        width: 120vw;
        top: 69vh !important;
        left: 10% !important;
        margin: 0 !important;
    }
    .mat-tab-label-container {
        max-width: 491px;
    }
}

@media all and (max-width: 425px) {
    .mat-tab-label-container {
        max-width: 320px;
    }
}

@media all and (max-width: 375px) {
    .mat-tab-label-container {
        max-width: 265px;
    }
}

@media all and (max-width: 320px) {
    .mat-tab-label-container {
        max-width: 212px;
    }
}

@media all and (max-width: 320px) {
    .mat-tab-label-container {
        max-width: 240px;
    }
}
/*Onboarding*/

/*Boton Traductor*/
.goog-te-banner-frame.skiptranslate {
    display: none;
} 

.goog-te-gadget img {
    display: none;
}

#goog-gt-tt {
    display: none !important;
}

.goog-text-highlight {
    background-color: transparent !important;
    box-shadow: none !important;
}

.goog-te-gadget-simple {
    border: 0px !important; 
    padding: 5px 10px !important;
    border-radius: 20px !important;
    font-size: 0.9rem !important;
    text-transform: uppercase;
    text-decoration: none !important;
    box-sizing: border-box;
    font-family: "Lato", arial, sans-serif;
    text-transform: uppercase !important;
    color: var(--primary) !important;
    display: flex !important;
    align-items: center !important;
    cursor: pointer !important;
}

.skiptranslate .goog-te-gadget {
    width: 120px;
}

/*#google_translate_element .goog-te-gadget-simple .goog-te-menu-value span:first-child {
    display: none;
}*/

#google_translate_element .goog-te-gadget-simple .goog-te-menu-value:before {
    //content: 'Traducir \25be';
    font-weight: bold;
    font-family: "Lato", arial, sans-serif;
}

#google_translate_element .goog-te-gadget-simple .goog-te-menu-value span  {
    padding-right: 2px;
    padding-left: 2px;
    font-weight: bold;
}

.goog-te-gadget-simple .goog-te-menu-value {
    text-transform: uppercase !important;
    color: var(--primary) !important;
    display: flex !important;
    align-items: center !important;
    cursor: pointer !important;
}

#google_translate_element span a:hover {
    text-decoration: none !important;
}

.goog-te-gadget-simple .goog-te-menu-value span {
    border-left: 0px solid rgb(187, 187, 187) !important;
}

.goog-te-menu2-item div, .goog-te-menu2-item:link div, .goog-te-menu2-item:visited div, .goog-te-menu2-item:active div {
    text-transform: uppercase !important;
    color: #000 !important;
    background: #ffffff !important;
}

.img-bandera {
    width: 20px;
    margin-left: 4px; 
    margin-bottom: -5px;
}

/*Boton Traductor*/

h1, h2, h3, h4, h5, h6 {
    margin: 0;
}

a {
    text-decoration: none;
    color: var(--primary);
}

a:hover {
    text-decoration: underline;
}

.overflow-hidden {
    overflow: hidden;
}

.text-center {
    text-align: center;
}






.module-header {
    margin-bottom: 20px;
}
/* .module-title {
    text-transform: uppercase;
    color: var(--primary);
} */

input,
select,
textarea {
    width: 100%;
    padding: 8px 16px;
    /* padding-top: 24px; */
    border: 1px solid #ddd;
    font-size: 1rem;
    color: inherit;
    background: white;
    border-radius: 3px;
}

input:focus,
select:focus,
textarea:focus {
    border-color: var(--primary);
    outline: none;
}

.input-container.error input,
.input-container.error select,
.input-container.error textarea {
    border-color: var(--red);
}

.input-container {
    margin-bottom: 10px;
    position: relative;
}

label {
    color: #888;
    font-size: .9rem;
    margin-bottom: 10px;
    display: inline-block;
}

.input-container label {
    /* position: absolute;  */
    /* top: 8px; */
    font-size: .85rem;
    margin: 0;
    left: 19px;
    /* text-transform: uppercase; */
    font-weight: bold;
    /* letter-spacing: 1px; */
    margin-bottom: 5px;
}

.select-title label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #232323;
}

.input-container.checkbox label,
.input-container.radio label {
    position: static;
    font-size: 1rem;
    transform: none;
    text-transform: none;
    font-weight: normal;
    letter-spacing: 0;
    display: inline-block;
    color: var(--text);
    cursor: pointer;
}

.input-container.disabled label {
    color: var(--grey);
}

input[type=checkbox],
input[type=radio] {
    display: none;
    width: auto;
}

.disabled input[type=checkbox] {
    pointer-events: none;
}

.disabled input[type=checkbox] + span {
    border-color: #ddd;
}
input[type=checkbox] + span {
    height: 18px;
    width: 18px;
    border: 2px solid var(--primary);
    display: inline-block;
    margin-right: 5px;
    vertical-align: bottom;
    position: relative;
}

.input-container.error.checkbox input + span {
    border-color: var(--red);
}

.disabled input[type=checkbox]:checked + span {
    background: #ddd;
}
input[type=checkbox]:checked + span {
    background: var(--primary);
}

input[type=checkbox]:checked + span::after {
    font-family: 'Line Awesome Free';
    font-weight: 900;
    content: "\f00c";
    color: white;
    font-size: .9rem;
    position: absolute;
    top: 0;
    left: 0;
    margin-top: -1px;
}

button[type=submit],
input[type=submit],
.btn.default {
    background: var(--primary);
    color: white;
    font-weight: bold;
    /* text-transform: uppercase; */
    /* letter-spacing: 1px; */
    display: inline-block;
    border: none;
    padding: 10px 20px;
    min-width: 140px;
    cursor: pointer;
    transition: .3s;
    border-radius: 4px;
}

.btn.default.aceptar {
    background: #1E9AD6;
    color: white;
    font-weight: bold;
    /* text-transform: uppercase; */
    /* letter-spacing: 1px; */
    display: inline-block;
    border: none;
    padding: 10px 20px;
    min-width: 140px;
    cursor: pointer;
    transition: .3s;
    border-radius: 4px;
    width: 100%;
}

.btn.default.small,
.btn.danger.small {
    padding: 4px 12px;
    font-size: .8rem;
    min-width: unset;
    border-radius: 3px;
    display: inline-block;
}

.btn.small + .btn.small {
    margin-left: 10px;
}

.btn.outline {
    background: white;
    border: 2px solid var(--primary);
    color: var(--primary);
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    color: #1E9AD6;

}

.btn.outline:hover {
    background: var(--primary);
    color: white;
    opacity: 0.8;
    text-decoration: none;
}

button[type=submit]:hover,
input[type=submit]:hover {
    box-shadow: 1px 1px 30px var(--light-primary);
    transform: translateY(-2px);
}

button.text-only {
    min-width: none;
    background: transparent;
    padding: 0;
    font-weight: bold;
    color: var(--primary);
    border: none;
    font-size: 1rem;
    cursor: pointer;
}

input:disabled {
    background: #f3f3f3;
    border-color: #f3f3f3;
}

.error-block {
    color: var(--red);
    margin-top: -15px;
    margin-bottom: 20px;
    font-size: .9rem;
}

.red {
    color: var(--red);
}

.green {
    color: var(--green);
}

.form-footer {
    text-align: center;
    margin-top: 20px;
}

.form-footer button + button {
    margin-left: 10px;
}

@media (min-width: 768px) {
    button[type=submit],
    input[type=submit],
    .btn.default {
        min-width: 180px;
    }
    .btn.default.aceptar {
        min-width: 180px;
    }
    .form-footer {
        text-align: right;
        margin-top: 40px;
    }
    .form-footer button + button {
        margin-left: 10px;
    }
}




.inner-sidebar {
    width: 100%;
    /* max-width: var(--max-width); */
    display: grid;
    grid-gap: 40px;
}

.inner-sidebar > .module:first-child {
    order: 1;
}

.module {
    width: 100%;
    max-width: var(--max-width);
    background: white;
    padding: 15px;
    box-shadow: 0 0 30px rgba(0,0,0,0.05);
    border-radius: 10px;
    margin: 0 auto;
}

.module.form {
    max-width: var(--max-form-width);
}

.action-link {
    padding: 8px 20px;
    margin-bottom: 10px;
    display: inline-flex;
    align-items: center;
    transition: .3s;
    background: transparent;
    color: var(--primary);
    border-radius: 30px;
    border: 2px solid var(--primary);
    cursor: pointer;
    /* font-weight: bold; */
    display: block;
    /* text-transform: uppercase; */
    font-size: .8rem;
    font-weight: bold;
}


.action-link-reporte {
    padding: 8px 20px;
    margin-bottom: 10px;
    display: inline-flex;
    align-items: center;
    transition: .3s;
    background: var(--primary);
    color: white;
    border-radius: 30px;
    border: 2px solid var(--primary);
    cursor: pointer;
    /* font-weight: bold; */
    display: block;
    /* text-transform: uppercase; */
    font-size: .8rem;
    font-weight: bold;
}

.action-link-reporte-disabled {
    padding: 8px 20px;
    margin-bottom: 10px;
    display: inline-flex;
    align-items: center;
    transition: .3s;
    background: #eee;
    color: var(--light-grey);
    pointer-events: none;
    border-radius: 30px;
    border: 2px solid var(--light-grey);
    /* font-weight: bold; */
    display: block;
    /* text-transform: uppercase; */
    font-size: .8rem;
    font-weight: bold;
}

.action-link-reporte:hover {
    text-decoration: none;
    box-shadow: 1px 1px 30px var(--light-primary);
    transform: translateY(-2px);
}

@media (min-width: 768px) {
    .module-header {
        display: flex;
        justify-content: space-between;
    }
    .action-link { display: inline-block; }
    .action-link + .action-link {
        margin-left: 10px;
    }
    .action-link-reporte { display: inline-block; }
    .action-link-reporte + .action-link-reporte {
        margin-left: 10px;
    }

    .action-link-reporte-disabled { display: inline-block; }
    .action-link-reporte-disabled + .action-link-reporte-disabled {
        margin-left: 10px;
    }
}

.action-link i {
    margin-right: 10px;
}

.action-link:hover {
    background: var(--primary);
    color: white;
    opacity: .8;
    text-decoration: none;
}

a.action-link.disabled {
    color: #888;
    border-color: #ddd;
    background: transparent;
    cursor: auto;
}

.one-row,
.double-row,
.double-row-scroll,
.double-row-paginas,
.double-row-button,
.double-row-select,
.double-spinner,
.triple-row {
    display: grid;
}

.double-row.flex-end {
    align-items: flex-end;
}

@media (min-width: 768px) {
    .module {
        padding: 30px;
    }
    .one-row {
        grid-gap: 20px;
        grid-template-columns: 1fr;
    }
    .double-row {
        grid-gap: 20px;
        grid-template-columns: 1fr 1fr;
    }
    .double-row-select {
        grid-template-columns: 25px 1fr;
    }
    .double-row-scroll {
        //grid-gap: 20px;
        //grid-template-columns: auto 1fr 320px;
        grid-template-columns: auto 1fr;
        background: #F9F9F9;
        border-radius: 0px 0px 18px 18px;
    }
    .double-row-paginas {
        //grid-gap: 20px;
        grid-template-columns: 1fr 0px;
    }
    .double-row-button {
        //grid-gap: 20px;
        grid-template-columns: 45px 1fr;
        z-index: 2;
        border-radius: 0px 0px 0px 18px;
    }

    .double-spinner {
        //grid-gap: 20px;
        grid-template-columns: 100px;
    }

    .triple-row {
        grid-gap: 20px;
        grid-template-columns: 1fr 1fr 1fr;
    }
}
@media (min-width: 1300px) {
    /* .inner-sidebar {
        grid-template-columns: 3fr 1fr;
    }
    .inner-sidebar > .module:first-child {
        order: unset;
    } */
}





button.btn {
    font-weight: bold;
    /* text-transform: uppercase; */
    /* letter-spacing: 1px; */
    display: inline-block;
    border: none;
    padding: 10px 16px;
    min-width: 140px;
    cursor: pointer;
    transition: .3s;
    //border-radius: 30px;
    border-radius: 4px;
}

button.btn.success {
    background: var(--primary);
    color: white;
}

button.btn.success:hover {
    box-shadow: 1px 1px 30px var(--light-primary);
    transform: translateY(-2px);
}

button.btn.secondary {
    background: var(--light-primary);
    color: var(--primary);
}

button.btn.secondary:hover {
    box-shadow: 1px 1px 30px var(--light-primary);
    transform: translateY(-2px);
}

button.btn.danger {
    background: var(--red);
    color: white;
}

.danger {
    color: var(--red);
}

button.btn.danger:not(.small):hover {
    box-shadow: 1px 1px 30px var(--light-red);
    transform: translateY(-2px);
}

button.btn.disabled,
button.btn.loading {
    background: #eee;
    color: var(--light-grey);
    pointer-events: none;
}

@media (min-width:768px) {
    button.btn,
    button[type=submit] {
        min-width: 180px;
    }
}


button.btn.text-only {
    background: transparent;
}
button.btn.text-only.default {
    color: var(--primary);
}
button.btn.text-only.danger {
    color: var(--red);
}




signature-pad {
    background: #FFFFFF !important;
}

signature-pad canvas {
    width: 300px;
    margin-right: auto;
    border: 0px solid #ddd !important;
    border-bottom: 1px solid #E6E5E5 !important;
    margin-bottom: 15px !important;
}






.shrink {
    visibility: hidden;
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 100% 0;
            transform-origin: 100% 0;
}
.shrink.show {
    visibility: visible;
    -webkit-transform: scale(1);
            transform: scale(1);
}
.fade {
    visibility: hidden;
    opacity: 0;
}
.fade.show {
    visibility: visible;
    opacity: 1;
}

@supports not (-webkit-backdrop-filter: blur(1px)) {
    .notifications {
        -webkit-transition: .5s !important;
        transition: .5s !important;
    }
    .shrink {
        -webkit-transition: .15s;
        transition: .15s;
    }
    .fade {
        -webkit-transition: .15s;
        transition: .15s;
        /* visibility: hidden; */
    }
    .slide {
        -webkit-transition: .15s;
        transition: .15s;
    }
}






button.loading::after,
span.loading::after,
.action-link.loading::after {
  content: '';
  display: inline-block;
  animation: load 1.5s infinite ease-in-out;
  width: 10px;
}

@keyframes load {
    0% {
      content: '';
    }
  
    33.3% {
      content: '.';
    }
  
    66.6% {
      content: '..';
    }
  
    100% {
      content: '...';
    }
}






.error-message {
    margin-top: -15px;
    color: var(--red);
}






.divisor {
    display: inline-block;
    height: 3px;
    width: 3px;
    border-radius: 50%;
    background: var(--grey);
    margin: 0 8px;
    margin-bottom: 3px;
}







.check-box {
    display: inline-block;
    width: 18px;
    height: 18px;
    border: 2px solid var(--primary);
    margin-right: 5px;
    position: relative;
    border-radius: 4px;
}

.check-box.checked {
    background: var(--primary);
}

.check-box.checked::after {
    content: "\f00c";
    font-family: 'Line Awesome Free';
    font-weight: 900;
    color: white;
    position: absolute;
    top: -1px;
    left: -1px;
}

.check-box + em {
    font-style: italic;
    color: var(--grey);
}

.check-box.disabled {
    border: 2px solid var(--grey) !important;
    background-color: #969696;
}

.check-box.disabled + span {
    color: #969696;
}

.text-center {
    text-align: center;
}






.button-grid {
    width: 100%;
    display: grid;
    margin-bottom: 20px;
    grid-gap: 5px;
}

.button-grid button,
.button-grid .check-box {
    background: transparent;
    width: 100%;
    height: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid var(--light-primary);
    transition: .2s;
}

.button-grid button.active,
.button-grid .check-box.active {
    background: var(--light-primary);
    border-color: var(--primary);
}

.button-grid button:hover {
    transform: translateY(-2px);
}

.button-grid button:focus {
    outline: none;
}

.button-grid button i,
.button-grid .check-box i {
    font-size: 2rem;
    margin-right: 20px;
    color: var(--primary);
    margin-right: 10px;
}

.button-grid .check-box {
    position: relative;
    height: 70px;
    color: inherit;
}

.button-grid .check-box input + span {
    position: absolute;
    top: 5px;
    right: 5px;
    margin-right: 0;
}

.button-grid button.disabled {
    border-color: #eee;
    color: #aaa;
    cursor: auto;
    background: #f9f9f9;
    pointer-events: none;
}

.button-grid button.disabled i {
    color: #aaa;
    pointer-events: none;
}

@media (min-width: 768px) {
    .button-grid button,
    .button-grid .check-box {
        height: 100px;
    }
    .button-grid {
        grid-gap: 20px;
        grid-template-columns: 1fr 1fr 1fr;
    }
}







ul.upload-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

ul.upload-list li {
    border-bottom: 1px dashed #ddd;
    padding-bottom: 8px;
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
}

.icon-success {
    display: flex;
    margin: 0 auto;
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    background: rgba(120, 223, 78, .6);
    border-radius: 50%;
    font-size: 2rem;
    color: #589241;
}

.icon-error {
    display: flex;
    margin: 0 auto;
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    background: rgba(223, 78, 78, .6);
    border-radius: 50%;
    font-size: 2rem;
    color: #734e4e;
}






table .input-container {
    margin-bottom: 0;
}







.loadingInProgress.body {
    background: transparent !important;
}

div#viewer {
    background: transparent !important;
}

ngx-extended-pdf-viewer .toolbar {
    border: none !important;
}

ngx-extended-pdf-viewer #toolbarContainer, ngx-extended-pdf-viewer .findbar, ngx-extended-pdf-viewer .secondaryToolbar {
    background-color: #000 !important;
    height: unset;
}

ngx-extended-pdf-viewer .pdfViewer .page {
    margin: 40px auto !important;
}

ngx-extended-pdf-viewer span#scaleSelectContainer select {
    background: #222 !important;
    border: none;
}

ngx-extended-pdf-viewer .toolbarField.pageNumber {
    background: #222;
}

ngx-extended-pdf-viewer #errorMoreInfo {
    color: #444;
}

ngx-extended-pdf-viewer #viewerContainer::-webkit-scrollbar {
    width: 6px;
}

ngx-extended-pdf-viewer #viewerContainer::-webkit-scrollbar-thumb {
    background: var(--primary);
    border-radius: 20px;
}

html[dir=ltr] ngx-extended-pdf-viewer #toolbarViewerRight {
    margin-right: 60px !important;
}

.dashed {
    border: none;
    border-top: 1px dashed #ccc;
    margin: 30px 0;
}

.solid-firma {
    border: none;
    border-top: 1px solid #E3E3E3;
    margin: 22px 0;
}






.empty {
    color: #999;
    text-align: center;
    padding: 40px 20px;
}

.empty h5 {
    margin-bottom: 5px;
}

.empty i {
    font-size: 5rem;
    color: #ddd;
    margin-bottom: 10px;
}

.empty button {
    margin-top: 20px;
}








.upload-list-container {
    margin-top: 40px;
}

.upload-list-container p {
    text-align: center;
    font-size: .9rem;
    color: var(--grey);
}








.pad-container {
    position: relative;
    display: inline-block;
    width: 100%;
    background: #f9f9f9;
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pad-container .clear {
    position: absolute;
    top: 5px;
    left: 5px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 12px 14px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #9E9E9E;
}







.process-info {
    text-align: center;
    font-size: .9rem;
    color: #888;
}









.tag {
    font-size: .75rem;
    padding: 3px 5px;
    border-radius: 3px;
    font-weight: bold;
}

.tag.success {
    background: #deffb3;
    color: #6bac42;
}

.tag.info {
    background: #fff3c9;
    color: #d19b27;
}

.tag.primary {
    background: #dbeaff;
    color: #6093d7;
}

.tag.danger {
    background: #fee0e0;
    color: #eb6b6b;
}





.light-table {
    margin-bottom: 20px;
}

.light-table .body {
    max-height: 200px;
    overflow: auto;
    font-size: .9rem;
}

.light-table .body::-webkit-scrollbar {
    width: 6px;
    background: #f1f1f1;
}

.light-table .body::-webkit-scrollbar-thumb {
    background: var(--primary);
}

.light-table .header,
.light-table .row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.light-table .header {
    text-transform: uppercase;
    font-weight: bold;
}

.light-table .header div {
    padding: 5px 0;
}

.row div {
    padding: 5px 0;
    border-top: 1px solid #ddd;
}

.light-table .check {
    text-align: center;
}

.light-table .check .check-box.checked::after {
    left: 0;
}

.d-flex {
    display: flex;
}

.justify-content-center {
    justify-content: center;
}

.align-items-center {
    align-items: center;
}

.cursor-pointer {
    cursor: pointer;
}

.table-actions {
    text-align: right;
    margin-bottom: 20px;
}

/*.table-actions button {
    font-weight: bold;
    color: var(--primary);
    background: transparent;
    border: 2px solid;
    border-radius: 3px;
    padding: 10px 20px;
    text-transform: uppercase;
    cursor: pointer;
}*/
.table-actions button {
    font-weight: bold;
    color: var(--primary);
    background: transparent;
    border: 2px solid;
    border-radius: 25px;
    padding: 10px 15px;
    cursor: pointer;
}

.table-actions button.disabled {
    pointer-events: none;
    color: #ccc;
}

.btn-descargar {
    text-align: right;
    margin-bottom: 8px;
}

.btn-buscar {
    padding: 8px 30px;
}

.table-actions .action-link.disabled {
    pointer-events: none;
    color: #ccc;
}

.icon-message {
    font-size: .9rem;
    color: #777;
}
.icon-message i {
    border-radius: 50%;
    padding: 3px;
    color: white;
    margin-right: 5px;
}
.icon-message i.success {
    background: var(--green);
    
}
.icon-message i.error {
    background: var(--red);
}

.actions a i {
    font-size: 1.1rem;
}
.actions a {
    font-size: 0.9rem;
}




video {
    max-width: 100%;
    height: auto;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.modal-open { overflow-y: hidden; }

.sidebar-frente { z-index: 99 !important; }
.sidebar-detras { z-index: 0 !important; }

//Solo para modal comentarios
.modalComentarios .modal{
    max-width: 900px !important;
}

// -- Modales Visor Nuevo
//Modal PIN Certificado
.modalPinCertificado{
    .modal{
        padding: 20px !important;
        max-width: 450px !important;
    }
    .modal-close {
        border: 0px solid #000 !important;
        border-radius: 50% !important;
        padding: 1px 3px !important;
    }
    .modal-body{
        margin-top: 20px !important;
    }
    .modal-title{
        font-family: 'Poppins' !important;
        font-weight: 700 !important;
        font-size: 1.4em;
        text-transform: none !important;
        /* High Emphasis */
        color: #000000 !important;
    }
    input{
        font-family: 'Poppins' !important;
        font-weight: 600 !important;
        padding: 12px 0px ;
        color : #1E9AD6 ;
        text-align: center;
        border: 1px solid #1E9AD6 ;
        font-size: 1.5rem ;
        border-radius: 5px;
    }
    .form-footer{
        margin-top: 10px !important;
        text-align: center ;
        .btn-certificado{
            font-family: 'Poppins' !important;
            font-weight: 400 !important;
            border-radius: 5px !important;
            width: 100% !important;
        }
    }
    .modal-header .modal-close {
        top: 0px !important;
    }
}

//Modal Validacion Token
.modalValidacionToken{
    .modal{
        padding: 20px !important;
        max-width: 450px !important;
        border-radius: 16px !important;
    }
    .modal-close {
        border: 0px solid #000 !important;
        border-radius: 50% !important;
        padding: 1px 3px !important;
    }
    .modal-body{
        margin-top: 20px !important;
    }
    .countdown{
        margin: 0 auto 0px !important;
        background: transparent !important;
        color: #1E9AD6 !important;
    }
    .title{
        margin: 5px !important;
        font-family: 'Poppins' !important;
        font-weight: 700 !important;
        font-size: 1.4em;
        text-transform: none !important;
        color: #000000 !important;
        text-align: center;
    }
    .label-subtitle{
        font-family: 'Poppins' !important;
        font-weight: 400 !important;
        text-align: center;
        color: rgba(21, 25, 32, 0.50);
    }
    .form-footer{
        margin-top: 20px !important;
        text-align: center ;
        button{
            font-family: 'Poppins' !important;
            font-weight: 400 !important;
            border-radius: 5px !important;
            width: 100% !important;
        }
        .btn-reenvio{
            margin: 10px 0px 0px 0px !important;
        }
    }
    .input-token-container{
        display: inline-flex !important;
        .input-container{
            margin-left: 10px !important;
        }
        input{
            width: 75.5px;
            height: 85px;
            font-weight: bold !important;
            font-style: 700 !important;
            padding: 12px 0px ;
            color : #1E9AD6 ;
            text-align: center;
            border: 1px solid #1E9AD6 ;
            font-size: 2.5rem !important;
            border-radius: 5px;
        }
    }
}

//Modal Cerrar Documento, Cerrar Visor
.modalCerrarDocumento,.modalCerrarVisor{
    .modal{
        padding: 20px !important;
        max-width: 450px !important;
    }
    .modal-header{
        display: none;
    }
    .modal-body{
        margin-top: 20px !important;
    }
    .title{
        margin: 5px !important;
        font-family: 'Poppins' !important;
        font-weight: 700 !important;
        font-size: 1.4em;
        text-transform: none !important;
        color: #000000 !important;
        text-align: center;
    }
    .label-subtitle{
        font-family: 'Poppins' !important;
        font-weight: 400 !important;
        text-align: center;
        color: rgba(21, 25, 32, 0.50);
    }
    .form-footer{
        margin-top: 10px !important;
        text-align: center ;
        display: flex !important;
        button{
            font-family: 'Poppins' !important;
            font-weight: 400 !important;
            border-radius: 5px !important;
            width: 50% !important;
        }
        .btn-cancelar{
            border: 2px solid #1E9AD6 !important;
            color: #1E9AD6 !important;
            background-color: #fff !important;
        }
    }
}

//Modal Firma PDF
.modalDibujar{
    h2,h4,.mat-tab-label-content{
        font-family: 'Poppins' !important;
        font-weight: 700 !important;
        font-size: 16px !important;       
    }
    .mat-tab-label{
        min-width: 120px !important;  
        padding-left: 20px !important;
        margin-top: 15px !important;
    }
    em,label,input,textarea,button{
        font-family: 'Poppins' !important;
        font-weight: 400 !important;
    }
    .modal-close {
        border: 0px solid #000 !important;
        border-radius: 50% !important;
        padding: 0px 0px !important;
    }
    .modal-title{
        font-family: 'Roboto' !important;
        font-style: normal !important;
        font-weight: 700 !important;
        font-size: 1.4em !important;
        color: #232323 !important;
        text-transform: none !important;
    }
    .modal{
        max-width: 750px !important;
    }
    .modal-body{
        margin-top: 10px !important;
    }
    .form-footer{
        margin-top: 20px !important;
        text-align: center ;
        display: flex !important;
        button{
            border-radius: 5px !important;
            width: 50% !important;
            font-family: 'Poppins'!important;
            font-style: normal !important;
            font-weight: 600 !important;
            font-size: 16px !important;
        }
        .btn-cancelar{
            border: 2px solid #1E9AD6 !important;
            color: #1E9AD6 !important;
            background-color: #fff !important;
        }
    }
}