@import 'variables';
@import 'mixins/breakpoints';

@each $size, $size-value in $sizes {
    .w-#{$size} {
        width: $size-value;
    }
}

@each $key, $value in $display-breakpoints {
    @include query-min($key) {
        
        @each $size, $size-value in $sizes {
            .w-#{$key}-#{$size} {
                width: $size-value;
            }
        }
    }
}